import * as React from "react";
import * as ReactDOM from "react-dom";

const mountSearch = (): void => {
  document.addEventListener("DOMContentLoaded", async () => {
    const searchRoot = document.querySelector("#algolia-search");

    if (searchRoot instanceof HTMLElement) {
      const { Search } = await import(
        /* webpackPreload: true */
        "../src/search"
      );
      const environment = searchRoot.dataset.environment ?? "development";

      ReactDOM.render(<Search environment={environment} />, searchRoot);
    }
  });
};

mountSearch();
